<template>
  <el-menu
    :default-active="this.$store.state.menus.editableTabsValue"
    class="el-menu-vertical-demo"
    background-color="rgb(30, 107, 178)"
    text-color="#fff"
    mode="horizontal"
  >
    <el-menu-item
      style="background: rgb(30, 107, 178); color: #fff"
      index="Index"
      @click="selectMenu({ name: 'Index', title: $t('global.shouye') })"
    >
      <template slot="title">
        <span slot="title">{{ $t('global.shouye') }}</span>
      </template>
    </el-menu-item>

    <el-submenu
      :index="meu.name"
      v-for="meu in menuList"
      :key="meu.name"
      style="color: #fff"
    >
      <template slot="title">
        <span>{{ meu.title }}</span>
      </template>

      <router-link
        :to="item.path"
        v-for="item in meu.children"
        :key="item.name"
      >
        <el-menu-item
          :index="item.name"
          @click="selectMenu(item)"
          style="background: rgb(30, 107, 178); color: #fff"
        >
          <template slot="title">
            <span slot="title">{{ item.title }}</span>
          </template>
        </el-menu-item>
      </router-link>
    </el-submenu>

    <el-menu-item
      style="background: rgb(30, 107, 178); color: #fff"
      index="NetUrl"
      @click="
        selectMenu({
          name: 'NetUrl',
          query: { url: 'https://ai.12348.gov.cn/pc/' },
        })
      "
    >
      <template slot="title">
        <span slot="title">{{ $t('global.zhinengfalvzixun') }}</span>
      </template>
    </el-menu-item>

    <el-menu-item
      style="background: rgb(30, 107, 178); color: #fff"
      index="NetUrl1"
      @click="
        selectMenu({
          name: 'NetUrl',
          query: { url: 'https://flk.npc.gov.cn/' },
        })
      "
    >
      <template slot="title">
        <span slot="title">{{ $t('global.falvfaguichaxun') }}</span>
      </template>
    </el-menu-item>

    <el-menu-item
      style="background: rgb(30, 107, 178); color: #fff"
      index="NetUrl2"
      @click="
        selectMenu({
          name: 'NetUrl',
          query: { url: 'http://alk.12348.gov.cn/' },
        })
      "
    >
      <template slot="title">
        <span slot="title">{{ $t('global.anliku') }}</span>
      </template>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  name: "SideMenu",
  data() {
    return {
      menuList: [],
      clientHeigth: 500,
    };
  },
  mounted() {
    this.clientHeigth = window.innerHeight - 2;
    this.menuList = this.$store.state.menus.menuList;
    window.onresize = () => {
      return (() => {
        this.clientHeigth = window.innerHeight - 2;
        // window.screenWidth = document.body.clientWidth
        // that.screenWidth = window.screenWidth
      })();
    };
  },
  computed: {
    // menuList: {
    //   get() {
    //     return this.$store.state.menus.menuList;
    //   },
    // },
  },
  watch: {
    "$store.state.menus.menuList"(val) {
      this.menuList = val;
    },
  },
  methods: {
    selectMenu(item) {
      this.$router.push(item);
      // this.$store.commit("addTab", item);
    },
  },
};
</script>

<style scoped>
.el-menu--horizontal > .el-submenu.is-active ::v-deep(.el-submenu__title) {
  color: #fff;
}
::v-deep(.el-submenu__title),
::v-deep(.el-menu-item) {
  background-color: transparent !important;
}
/* .el-menu-vertical-demo {
  height: 100%;
} */
/* /deep/.el-submenu .el-menu-item {
  padding: 0;
  min-width: 150px;
}
/deep/ el-menu-item {
  padding: 0 !important;
} */
</style>
